import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preferences } from '@capacitor/preferences';
import { actions, RootState, ThunkDispatchType } from "../../store";
import { useMountEffect } from "../../utils/hooks";
import { trackPageView } from "../../utils/appAnalyticsUtils";
import LoginStep from "./Login";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { ONBOARD_STEP, PAGE_URL } from "../../constants";
import { Box } from "@mui/material";
import colors from "../../colors";
import { CABINET_AUTH_ERROR_CODE, COGNITO_ERROR_CODE } from "../../utils/cognitoErrorUtils";
import { cabCaptureException } from "../../utils/logging";
import { AmplifyAuthProvider } from "../../utils/authUtils";
import { signInWithRedirect as amplifySignInWithRedirect } from 'aws-amplify/auth';
import { core } from "../../store/cabinetApi/core";
import { skipToken } from "@reduxjs/toolkit/query";


export type LoginStepContainerProps = Record<string, unknown>;

const LoginStepContainer = (): ReactElement => {
  const { isLoading } = useSelector((state: RootState) => state.auth);
  // const organization = useSelector((state: RootState) => state.organization);
  const dispatch: ThunkDispatchType = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { orgCode } = useParams<{orgCode?: string}>();
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [email, setEmail] = useState<string|undefined>(undefined);

  const { data: organization, isError } = core.useCoreOrgPublicOrgRetrieveQuery(orgCode || skipToken);

  const orgExists = !isError;

  useMountEffect(() => {
    trackPageView('Login');
  });

  useMountEffect((): void => {
    // Sometimes Capacitor's bridge isn't quite ready so we give if a bit of buffer time.
    //  The better way to do this would be to listen for ondeviceready or something and make a queue
    //  based on that, but even with the errors this still seems to work, so it might already do a retry.
    setTimeout(() => {
      Preferences.get({key: 'email'})
        .then((data): void => {
          if (data.value) {
            setEmail(data.value);
          }
        }).catch((e) => {
          console.log('Error retrieving email address from Storage');
          cabCaptureException(Error('Error retrieving email address from Storage'));
        });
    }, 500);
  });

  const handleLogin = async (userEmail: string, password: string) => {
    try {
      const data = (await dispatch(actions.auth.login(userEmail, password, true)));
      if (data?.status === 400) {
        const errorDetail = data.data["detail"];
        setSubmissionErrors([errorDetail.message]);

        if (
          errorDetail.errorType === COGNITO_ERROR_CODE.OTHER_EMAIL_NOT_CONFIRMED ||
          errorDetail.errorType === COGNITO_ERROR_CODE.USER_NOT_CONFIRMED_EXCEPTION
        ) {
          navigate(PAGE_URL.SIGNUP, {
            replace: true,
            state: {
              onboardStep: ONBOARD_STEP.VERIFY_EMAIL,
              onboardEmail: userEmail,
              onboardPassword: password,
            }
          });
        } else if (errorDetail.errorType === CABINET_AUTH_ERROR_CODE.SSO_REQUIRED) {
          const userOrgCode = errorDetail.extra?.org_code;
          if (userOrgCode) {
            window.location.href = `${PAGE_URL.LOGIN}/${userOrgCode}`;
          }
        }
      } else if ('onLine' in navigator && !navigator.onLine) {
        setSubmissionErrors(['Offline - please check your internet connection']);
      } else if (data && 'id' in data) {
        // A valid response, so the email was valid. Save it.
        await Preferences.set({key: 'email', value: userEmail});
      } else {
        // Something unexpected went wrong...wait a bit and re-enable the button
        //   so they try again.
        // setTimeout(() => setButtonDisabled(false), 1500);
      }
    } catch (err) {
      // setButtonDisabled(false);
      if (err instanceof Error) {
        setSubmissionErrors([err.message]);
      } else {
        setSubmissionErrors([String(err)]);
      }
    }
  };

  // Hardcoding Google 
  const handleFederatedLogin = (
    {customProvider, provider}: 
    {customProvider?: string, provider?: AmplifyAuthProvider}
  ) => {
    if (location.state?.from?.pathname) {
      const customLogin = { redirectPath: location.state.from.pathname };
      const customState = JSON.stringify(customLogin);
      dispatch(actions.auth.federatedLogin({customProvider, provider, customState: customState}));
    } else {
      dispatch(actions.auth.federatedLogin({customProvider, provider}));
    }
  };

  const handleLoginWithGoogle = async () => {
    const authUrl = location.state?.from?.pathname;
    await amplifySignInWithRedirect({
      provider: "Google",
      customState: authUrl ? JSON.stringify({ redirectPath: authUrl }) : undefined,
    });
  };

  return (
    <Box sx={{ backgroundColor: colors.greyBackdrop, height: '100%', width: '100%' }} overflow='auto'>
      <Box paddingTop={10}>
        <LoginStep onSubmit={handleLogin} email={email} submissionErrors={submissionErrors} orgCode={orgCode} 
          orgExists={orgExists} organization={organization} loading={isLoading}
          federatedLogin={handleFederatedLogin} authUrl={location.state?.from?.pathname}
          onLoginWithGoogle={handleLoginWithGoogle}
        />
      </Box>
    </Box>
  );
};

export default LoginStepContainer;
